<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        :title="titleName"
        :filter="filter"
        @sidebar="sidebarFilter()"
        :hasDropdown="false"
        :hasSearch="false"
        :hasExport="hasExport"
        @export="exportModal"
      />
      <div>
        <div class="my-2 f-16" v-if="hasStat">
          <div v-if="stat.totalCustomer || stat.totalCustomer == 0">
            Total Customer:
            {{ stat.totalCustomer | numeral("0,0") }} ({{
              stat.percentCustomer
            }}%)
          </div>
          <div v-if="displayDateFilter">
            {{ displayDateFilter }}
          </div>
        </div>
        <p class="my-2 f-16" v-if="tabIndex == 1">
          {{ displayDateTimeFilter.startDate | moment($formatDate) }} -
          {{ displayDateTimeFilter.endDate | moment($formatDate) }}
        </p>
      </div>
      <div class="mt-3">
        <b-tabs v-model="tabIndex" @input="handleTabsChange">
          <b-tab title="Summary">
            <Summary
              ref="summary"
              v-if="tabIndex == 0"
              @setStat="setStat"
              @export="exportModal"
            />
          </b-tab>
          <b-tab title="Customer Analysis">
            <DashboardPanel
              v-if="tabIndex == 1"
              :dashBoardData="dashBoardData"
              :dashBoardLabel="dashBoardLabel"
              :dashBoardDesc="dashBoardDesc"
              @exportExcel="exportExcel"
            />
          </b-tab>
          <b-tab title="Revenue"
            ><Revenue ref="revenue" v-if="tabIndex == 2" @setStat="setStat" />
          </b-tab>
          <b-tab title="Retention">
            <Retention
              ref="retention"
              v-if="tabIndex == 3"
              @setStat="setStat"
            />
          </b-tab>
          <b-tab title="RFM">
            <RFMPanel
              :RFMData="RFMData"
              v-if="tabIndex == 4 && !isLoadingRfm"
              @export="exportRFM"
            />
            <OtherLoading v-else />
          </b-tab>
          <b-tab title="First Purchase">
            <FirstPurchase
              ref="firstPurchase"
              v-if="tabIndex == 5"
              @setStat="setStat"
            />
          </b-tab>
          <!-- <b-tab title="Prospect"><Prospect ref="prospect" /></b-tab> -->
          <b-tab title="Connect Channel">
            <ConnectChannel
              ref="connectChannel"
              v-if="tabIndex == 6"
              @setStat="setStat"
            />
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <SidebarFilter
      ref="sidebarFilter"
      :filter="filter"
      @searchAll="filterSearch()"
      :hideSearchBar="true"
      :hideClearFilter="true"
      :hideStatusFilter="false"
    >
      <template v-slot:filter-option>
        <div class="mt-3">
          <div>
            <div class="main-label">Date From</div>
            <div :class="['input-container']">
              <datetime
                format="dd/MM/yyyy"
                v-model="filter.date_from"
                ref="startDate"
                @input="handleMinDate"
                placeholder="Please Select Date"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.startDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer color-primary"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div class="mt-3 mb-3">
            <div class="main-label">Date To</div>
            <div :class="['input-container']">
              <datetime
                format="dd/MM/yyyy"
                v-model="filter.date_to"
                :min-datetime="filter.date_from"
                placeholder="Please Select Date"
                ref="endDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.endDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer color-primary"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </SidebarFilter>
    <SidebarRFMFilter
      ref="sidebarRFMFilter"
      @filterSearch="filterRFMSearch"
      @clearFilter="clearFilter"
      v-if="tabIndex == 4"
    />
    <ModalInputEmail
      ref="ModalInputEmail"
      :form="filterExportFile"
      @closeModal="clearExportFilter"
      @changeEmail="(val) => (filterExportFile.email = val)"
      @submit="handleExportCustomer"
    />
  </div>
</template>

<script>
const moment = require("moment");
// import "moment-range";
import DashboardPanel from "@/components/dashboard/DashboardPanel";
import OtherLoading from "@/components/loading/OtherLoading";
import SidebarFilter from "@/components/SideBarFilter";
import HeaderPanel from "@/components/HeaderPanel";
import RFMPanel from "@/components/dashboard/RFM/RFMPanel";
import SidebarRFMFilter from "@/components/dashboard/RFM/SidebarFilter";
import Revenue from "@/components/dashboard/Revenue";
import Retention from "@/components/dashboard/Retention";
import FirstPurchase from "@/components/dashboard/FirstPurchase";
// import Prospect from "@/components/dashboard/Prospect";
import Summary from "@/components/dashboard/Summary";
import ConnectChannel from "@/components/dashboard/ConnectChannel";
import ModalInputEmail from "@/components/customer/ModalInputEmail";
import Chart from "chart.js";
function getRandomColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return "rgb(" + r + "," + g + "," + b + ")";
}

const chartColor = ["#b35fdd", "#c3adff", "#c3adff"];
const chartList = [
  {
    id: 1,
    name: "Prospect",
    desc: [
      {
        name: "Prospect To First Purchase Customer",
        key: "toFirstPurchasePercen",
      },
      {
        name: "Prospect To Inactive Prospect",
        key: "toInactiveProspectPercen",
      },
    ],
    data: [
      {
        label: "Prospect",
        data: [],
        borderColor: getRandomColor(),
        backgroundColor: getRandomColor(),
        fill: false,
        key: "prospect",
      },
      {
        label: "Prospect To Inactive Prospect",
        data: [],
        borderColor: getRandomColor(),
        backgroundColor: getRandomColor(),
        fill: false,
        key: "toInactiveProspect",
      },
      {
        label: "Prospect To First Purchase Customer",
        data: [],
        borderColor: getRandomColor(),
        backgroundColor: getRandomColor(),
        fill: false,
        key: "toFirstPurchase",
      },
    ],
    key: "prospect",
  },
  {
    id: 3,
    name: "First Purchase",
    desc: [
      {
        name: "First Purchase To Active Customer",
        key: "toActiveCustomerPercen",
      },
      { name: "First Purchase To Lapse", key: "toLapseCustomerPercen" },
    ],
    data: [
      {
        label: "First Purchase Customer",
        data: [],
        borderColor: getRandomColor(),
        backgroundColor: getRandomColor(),
        fill: false,
        key: "firstPuchase",
      },
      {
        label: "To Active Customer",
        data: [],
        borderColor: getRandomColor(),
        backgroundColor: getRandomColor(),
        fill: false,
        key: "toActiveCustomer",
      },
      {
        label: "To Lapse",
        data: [],
        borderColor: getRandomColor(),
        backgroundColor: getRandomColor(),
        fill: false,
        key: "toLapseCustomer",
      },
    ],
    key: "firstPuchase",
  },
  {
    id: 4,
    name: "Active Customer",
    desc: [
      {
        name: "Active Customer To Lapse",
        key: "toLapseCustomerPercen",
      },
    ],
    data: [
      {
        label: "Active Customer",
        data: [],
        borderColor: getRandomColor(),
        backgroundColor: getRandomColor(),
        fill: false,
        key: "activeCustomer",
      },

      {
        label: "To Lapse",
        data: [],
        borderColor: getRandomColor(),
        backgroundColor: getRandomColor(),
        fill: false,
        key: "toLapseCustomer",
      },
    ],
    key: "activeCustomer",
  },
  {
    id: 5,
    name: "Lapse",
    desc: [
      {
        name: "Lapse To Active Customer",
        key: "toActiveCustomerPercen",
      },
      {
        name: "Lapse To Inactive Customer",
        key: "toInactiveCustomerPercen",
      },
    ],
    data: [
      {
        label: "Lapse",
        data: [],
        borderColor: getRandomColor(),
        backgroundColor: getRandomColor(),
        fill: false,
        key: "lapseCustomer",
      },
      {
        label: "Lapse To Active Customer",
        data: [],
        borderColor: getRandomColor(),
        backgroundColor: getRandomColor(),
        fill: false,
        key: "toActiveCustomer",
      },
      {
        label: "Lapse To Inactive Customer",
        data: [],
        borderColor: getRandomColor(),
        backgroundColor: getRandomColor(),
        fill: false,
        key: "toInactiveCustomer",
      },
    ],
    key: "lapseCustomer",
  },
  {
    id: 2,
    name: "Inactive Prospect",
    key: "inactiveProspect",
    desc: [
      {
        name: "Inactive Prospect To First Purchase Customer",
        key: "toFirstPuchasePercen",
      },
    ],
    data: [
      {
        label: "Inactive Prospect",
        data: [],
        borderColor: getRandomColor(),
        backgroundColor: getRandomColor(),
        fill: false,
        key: "inactiveProspect",
      },
      {
        label: "To First Purchase Customer",
        data: [],
        borderColor: getRandomColor(),
        backgroundColor: getRandomColor(),
        fill: false,
        key: "toFirstPuchase",
      },
    ],
  },
  {
    id: 6,
    name: "Inactive Customer",
    desc: [
      {
        name: "Inactive Customer To Active Customer",
        key: "toActiveCustomerPercen",
      },
    ],
    data: [
      {
        label: "Inactive Customer",
        data: [],
        borderColor: getRandomColor(),
        backgroundColor: getRandomColor(),
        fill: false,
        key: "inactiveCustomer",
      },
      {
        label: "To Active Customer",
        data: [],
        borderColor: getRandomColor(),
        backgroundColor: getRandomColor(),
        fill: false,
        key: "toActiveCustomer",
      },
    ],
    key: "inactiveCustomer",
  },
];
export default {
  components: {
    DashboardPanel,
    RFMPanel,
    SidebarRFMFilter,
    OtherLoading,
    SidebarFilter,
    HeaderPanel,
    Revenue,
    Retention,
    FirstPurchase,
    Summary,
    ModalInputEmail,
    // Prospect,
    ConnectChannel,
  },
  data() {
    return {
      tabIndex: 0,
      items: [],
      filter: {
        year_from: moment().subtract(7, "day").year(),
        month_from: moment().subtract(7, "day").month() + 1,
        day_from: moment().subtract(7, "day").date(),
        year_to: moment().subtract(1, "day").year(),
        month_to: moment().subtract(1, "day").month() + 1,
        day_to: moment().subtract(1, "day").date(),
        date_from: moment().subtract(7, "day").format(),
        date_to: moment().subtract(1, "day").format(),
      },
      filterExportFile: {
        email: "",
      },
      filterNewRFM: {
        selectedAll: {
          channel: false,
          product: false,
          customerLifeCycle: false,
          recency: false,
          frequency: false,
          monetary: false,
        } /*  */,
        rate_scale: 5,
        member_tiers: [],
        channel_ids: [],
        branch_ids: [],
        age_group_ids: [],
        genders: [],
        products: [],
        recencys: [],
        frequency: [],
        monetary: [],
        repurchase: [],
        product: [],
        TransactionDateFrom: "",
        TransactionDateTo: "",
        LastDays: 365,
      },
      isLoading: true,
      isLoadingRfm: true,
      isBusy: false,
      dashBoardData: [],
      dashBoardLabel: [],
      dashBoardDesc: [],
      RFMData: [],
      hasExport: true,
      stat: {
        totalCustomer: 0,
        percentCustomer: 0,
      },
      displayDateFilter: "",
    };
  },
  created() {
    // if (this.$route.query.tabIndex) {
    //   this.tabIndex = parseInt(this.$route.query.tabIndex);
    // }
  },
  async mounted() {
    await this.getDashboardList();

    // if (this.$route.query.tabIndex != this.tabIndex) {
    //   this.$router.replace({
    //     query: { tabIndex: this.tabIndex },
    //   });
    // }
  },
  watch: {
    tabIndex(val) {
      this.stat = { totalCustomer: 0, percentCustomer: 0 };
      this.displayDateFilter = "";

      if ([0, 2, 5, 6, 7].includes(val)) this.hasExport = true;
      else this.hasExport = false;

      if (val == 1) {
        this.getDashboardList();
      } else if (val == 4) {
        this.getRFMList();
      }
    },
  },
  computed: {
    hasStat() {
      if ([0, 1, 2, 3, 4, 5, 6].includes(this.tabIndex)) return true;
      else return false;
    },
    RetentionCustomer() {
      if (this.$refs.retention) {
        return this.$refs.retention.stat.totalCustomer.toLocaleString();
      }
      return 0;
    },
    RetentionCustomerPercent() {
      if (this.$refs.retention) {
        return this.$refs.retention.stat.percentCustomer;
      }
      return 0;
    },
    titleName() {
      if (this.tabIndex == 0) return "Summary";
      else if (this.tabIndex == 1) return "Customer Analysis Dashboard";
      else if (this.tabIndex == 2) return "Revenue";
      else if (this.tabIndex == 3) return "Retention";
      else if (this.tabIndex == 4) return "RFM";
      else if (this.tabIndex == 5) return "First Purchase";
      // else if (this.tabIndex == 6) return "Prospect";
      else if (this.tabIndex == 6) return "Connect Channel";
    },
    displayDateTimeFilter() {
      let date = {
        startDate: this.filter.start_date,
        endDate: this.filter.end_date,
      };
      date = {
        startDate: this.filter.date_from,
        endDate: this.filter.date_to,
      };
      return date;
    },
  },
  methods: {
    handleTabsChange(a) {
      // this.$router.replace({
      //   query: { tabIndex: a },
      // });
    },
    async exportExcel(chartData) {
      const { id, name } = chartData;
      let payload = {
        report_life_cycle_type_id: id,
        ...this.filter,
      };
      this.isLoading = true;
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/dashboard/export`,
        payload,
        {
          responseType: "blob",
        }
      );

      let data = res;

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", name + `.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.isLoading = false;
    },
    async exportRFM(params) {
      // this.isLoading = true;
      this.$EventBus.$emit("showLoading");
      let payload = { ...this.filterNewRFM };
      payload.report_type = params;
      delete payload.selectedAll;

      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/dashboard/rfm_customer_report`,
        payload,
        {
          responseType: "blob",
        }
      );
      this.$EventBus.$emit("hideLoading");
      this.confirmAlert({
        message: "Do you want to download the file now?",
        title: "Success !",
        icon: "success",
        confirmButtonText: "Go To Page",
        cancelButtonText: "Close",
      }).then((val) => {
        if (val.isConfirmed) {
          // this.deleteItem(id);
          const routeData = this.$router.resolve({
            name: "Customer Report List",
          });
          window.open(routeData.href, "_blank");
        }
      });
      // this.isLoading = false;
    },
    getDashbardLabelFromFilter() {
      const startDate = moment(this.filter.date_from);
      const endDate = moment(this.filter.date_to).add(1, "day");

      const dateRange = [];
      let currentDate = startDate;

      while (currentDate.isBefore(endDate)) {
        dateRange.push(currentDate.clone().format("DD/MM/YYYY"));
        currentDate.add(1, "day");
      }
      this.dashBoardLabel = dateRange;
    },
    getDataFromDateLabel(data) {
      let result = this.dashBoardLabel.map((date) => {
        const value = data.find(
          (el) => date == moment(el.date_time).format("DD/MM/YYYY")
        );

        return value ? value.total : 0;
      });

      return result;
    },
    sidebarFilter() {
      if (this.tabIndex == 0) this.$refs.summary.$refs.filter.show();
      else if (this.tabIndex == 2)
        this.$refs.revenue.$refs.revenueFilter.show();
      else if (this.tabIndex == 3)
        this.$refs.retention.$refs.retentionFilter.show();
      else if (this.tabIndex == 4)
        this.$refs.sidebarRFMFilter.show(this.filterNewRFM);
      else if (this.tabIndex == 5)
        this.$refs.firstPurchase.$refs.firstPurchaseFilter.show();
      // else if (this.tabIndex == 6)
      // this.$refs.prospect.$refs.prospectFilter.show();
      else if (this.tabIndex == 6)
        this.$refs.connectChannel.$refs.filter.show();
      else this.$refs.sidebarFilter.show(true);
    },
    searchList() {
      if (this.tabIndex == 4) this.getRFMList();
      else this.getDashboardList();
    },
    async getDashboardList() {
      try {
        this.getDashbardLabelFromFilter();
        this.isLoading = true;
        const res = await this.$Axios.post(
          `${process.env.VUE_APP_REPORT_API}/dashboard/GetDashBoard`,
          this.filter
        );
        var response = res.data.detail;
        const color = Chart.helpers.color;
        chartList.forEach((chart) => {
          var currectKey = response[chart.key];
          let { data } = chart;
          data.forEach((chartData, index) => {
            chartData.backgroundColor = color(chartColor[index])
              .alpha(0.5)
              .rgbString();
            chartData.borderColor = color(chartColor[index]).rgbString();
            chartData.data = this.getDataFromDateLabel(
              currectKey[chartData.key]
            );
          });
        });

        this.dashBoardDesc = response;
        this.dashBoardData = chartList;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },

    filterSearch() {
      this.filter = {
        year_from: moment(this.filter.date_from).year(),
        month_from: moment(this.filter.date_from).month() + 1,
        day_from: moment(this.filter.date_from).date(),
        year_to: moment(this.filter.date_to).year(),
        month_to: moment(this.filter.date_to).month() + 1,
        day_to: moment(this.filter.date_to).date(),
        date_from: this.filter.date_from,
        date_to: this.filter.date_to,
      };

      this.getDashboardList();
    },

    // ------------RFM------------ //

    async getRFMList() {
      this.isLoadingRfm = true;

      this.filterNewRFM.products = this.filterNewRFM.product.map(
        (el) => el.value
      );
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/dashboard/rfm_dashboard_graph`,
        this.filterNewRFM
      );

      this.RFMData = res.data.detail.rfm_report;
      let date = `${this.$moment(res.data.detail.date_start).format(
        "DD MMM YYYY"
      )} - ${this.$moment(res.data.detail.date_end).format("DD MMM YYYY")}`;
      let stat = {
        totalCustomer: res.data.detail.total_customer,
        percentCustomer: res.data.detail.percent_customer,
      };
      this.setStat(stat, date);

      this.isLoadingRfm = false;
    },
    filterRFMSearch(value) {
      this.filterNewRFM = value;

      this.getRFMList();
    },
    clearFilter(filter) {
      this.filterNewRFM = filter;

      this.getRFMList();
    },
    exportModal() {
      this.handleExportCustomer();
    },
    handleMinDate() {
      let diff = this.$moment(this.filter.date_to).diff(
        this.$moment(this.filter.date_from),
        "min"
      );
      if (diff < 0) this.filter.date_to = "";
    },
    setStat(val, date) {
      this.displayDateFilter = date;
      // if (val) {
      this.stat.totalCustomer = val ? val.totalCustomer : 0;
      this.stat.percentCustomer = val ? val.percentCustomer : 0;
      // }
    },
    async handleExportCustomer() {
      var res = null,
        fileName = "";
      this.$EventBus.$emit("showLoading");
      switch (this.tabIndex) {
        case 0:
          // payload.email = this.filterExportFile.email;
          res = await this.$Axios.post(
            `${process.env.VUE_APP_REPORT_API}/DashBoard/summary/report/customer`,
            { ...this.$refs.summary.filterFreeze }
          );
          fileName = "Summary customer" + new Date();
          break;
        case 2:
          let payload = { ...this.$refs.revenue.filterFreeze };
          // payload.email = this.filterExportFile.email;
          res = await this.$Axios.post(
            `${process.env.VUE_APP_REPORT_API}/RevenueReport/revenue_customer_file`,
            payload
          );
          fileName = "Revenue" + new Date();
          break;
        case 4:
          res = await this.$Axios.post(
            `${process.env.VUE_APP_REPORT_API}/DashBoard/rfm_customer_report`,
            this.filterNewRFM,
            {
              responseType: "blob",
            }
          );
          fileName = "RFM" + new Date();
          break;
        case 5:
          // payload.email = this.filterExportFile.email;
          res = await this.$Axios.post(
            `${process.env.VUE_APP_REPORT_API}/Dashboard/first_purchase/export_report/customer`,
            { ...this.$refs.firstPurchase.filterFreeze }
          );
          fileName = "First Purchase" + new Date();
          break;
        // case 6:
        //   this.$refs.prospect.exportFile();
        case 6:
          // payload.email = this.filterExportFile.email;
          res = await this.$Axios.post(
            `${process.env.VUE_APP_REPORT_API}/dashboard/customer_connect/report_customer`,
            { ...this.$refs.connectChannel.filterFreeze }
          );
          fileName = "Connect Channel" + new Date();
          break;

        default:
          break;
      }
      if (
        this.tabIndex == 0 ||
        this.tabIndex == 2 ||
        this.tabIndex == 5 ||
        this.tabIndex == 6
      ) {
        if (res.data.result)
          this.confirmAlert({
            message: "Do you want to download the file now?",
            title: "Success !",
            icon: "success",
            confirmButtonText: "Go To Page",
            cancelButtonText: "Close",
          }).then((val) => {
            if (val.isConfirmed) {
              // this.deleteItem(id);
              const routeData = this.$router.resolve({
                name: "Customer Report List",
              });
              window.open(routeData.href, "_blank");
            }
          });
        else this.errorAlert(res.data.message);
      } else {
        let data = res;

        var fileURL = window.URL.createObjectURL(new Blob([data.data]));
        var fileLink = document.createElement("a");
        // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName + `.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
      this.$EventBus.$emit("hideLoading");
    },
    clearExportFilter() {
      this.filterExportFile.email = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-error {
  text-align: center;
  padding: 20px;
  color: #000;
}
// ::v-deep .nav.nav-tabs ::-webkit-scrollbar {
//   height: 2px;
// }
::v-deep .nav.nav-tabs {
  background-color: white;
  border: none;
  overflow-x: auto;
  flex-wrap: inherit;
  min-height: 40px;
  overflow-y: hidden;

  .nav-link.active,
  .nav-item.show .nav-link {
    background-color: transparent !important;
    color: var(--primary-color) !important;
    border-bottom: 4px solid var(--primary-color) !important;
  }
  .nav-link {
    border: none;
    border-color: #d8dbe0 !important;
    border-right: 1px solid #cfcfcf;
    // min-width: 150px;
    padding: 8px 30px;
    text-align: center;
    color: #333 !important;
  }
  :last-child .nav-link {
    border-right: 0 !important;
  }
}
::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
}
::v-deep .btn-export {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  text-decoration: underline;
  border: 0 !important;
  padding-right: 0 !important;
}

::v-deep .chart-title {
  color: var(--font-color);
  margin-bottom: 0.5rem !important;
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-weight: 700 !important;
  .title {
    font-size: 20px;
  }
  .sub-titile {
    color: #717171 !important;
    font-weight: normal !important;
    font-size: 16px;
  }
}
</style>
