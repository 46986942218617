<template>
  <div class="bg-white pt-3">
    <SideBarFilter
      ref="filter"
      :filter="filter"
      :placeholder="'Search Name'"
      @clearFilter="clearFilter"
      @searchAll="filterActions"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <div class="mb-3 input-select">
          <InputSelect
            title="Date"
            name="date"
            class="mt-2"
            v-model="filter.LastDays"
            :options="options.dateType"
            valueField="value"
            textField="text"
          >
            <template v-slot:option-first>
              <b-form-select-option value="" disabled
                >-- Select Date --</b-form-select-option
              >
            </template>
          </InputSelect>
        </div>
        <div v-if="filter.LastDays == 0" class="mb-3">
          <InputDatePickerFilter
            textFloat="Date From"
            name="date-from"
            ref="date-from"
            :value="filter.DateFrom"
            :max-datetime="filter.DateTo || initFilterDate.MaxDate"
            @input="(val) => (filter.DateFrom = val)"
          />
        </div>
        <div v-if="filter.LastDays == 0" class="mb-3">
          <InputDatePickerFilter
            textFloat="Date To"
            name="date-to"
            ref="date-to"
            :min-datetime="filter.DateFrom"
            :max-datetime="initFilterDate.MaxDate"
            :value="filter.DateTo"
            @input="(val) => (filter.DateTo = val)"
          />
        </div>
        <div class="my-3">
          <label class="main-label"> Member tier</label>
          <multiselect
            :close-on-select="false"
            :clear-on-select="false"
            multiple
            v-model="filter.MemberTierFilter"
            :options="options.memberTiers"
            placeholder="Select Member Tier"
            track-by="id"
            label="memberTierName"
          ></multiselect>
        </div>
        <div class="mb-3">
          <label class="main-label"> Channel</label>
          <multiselect
            :close-on-select="false"
            :clear-on-select="false"
            multiple
            v-model="filter.ChannelFilter"
            :options="options.channelTypes"
            placeholder="Select Channel"
            track-by="id"
            label="channelName"
          ></multiselect>
        </div>
      </template>
    </SideBarFilter>
    <b-container>
      <b-row>

        <b-col
          cols="12"
          v-for="(chart, index) in chartList"
          :key="index"
          class="resize"
        >
          <b-card
            class="shadow-sm border-none text-black rounded-lg"
            v-if="chart.isChart"
          >
            <div>
              <div class="d-flex justify-content-between mb-3">
                <div class="chart-title-name">{{ chart.name }}</div>
                <div class="d-flex" style="column-gap: 5px">
                  <div
                    :class="`f-14 underline cursor-pointer`"
                    v-for="chartFilter in chart.filter"
                    :key="chartFilter + '-' + chart.name"
                    @click="handleChartFilter(chartFilter, chart)"
                  >
                    <span
                      :class="`${
                        chart.filterValue == chartFilter ||
                        chartFilter == 'Export'
                          ? 'text-highlight'
                          : 'text-gray'
                      }`"
                      >{{ chartFilter }}</span
                    >
                  </div>
                </div>
              </div>

              <template v-if="chart.isFetch">
                <ChartLoading :id="'loading-' + chart.name" type="line" />
              </template>
              <template v-else>
                <BasketChart
                  :id="chart.name"
                  :chartData="chart.data"
                  :text="chart.text"
                  class="mt-auto"
                  :hideLegend="chart.hideLegend"
                  :label="chart.label"
                  :type="chart.type"
                  :filterValue="chart.filterValue"
                  :xText="chart.xText"
                  :isStacked="chart.isStacked"
                />
              </template>
            </div>
          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card class="shadow-sm border-none text-black rounded-lg">
            <div class="mb-2 d-flex justify-content-between">
              <div class="chart-title-name">{{ chartList[1].name }}</div>
              <div class="d-flex align-items-center" style="column-gap: 5px">
                <div
                  class="f-14 underline cursor-pointer"
                  v-for="chartFilter of chartList[1].filter"
                  :key="chartFilter + '-' + chartList[1].name"
                  @click="handleChartFilter(chartFilter, chartList[1])"
                >
                  <span class="text-highlight">{{ chartFilter }}</span>
                </div>
              </div>
            </div>
            <div class="shadow-sm mb-3">
              <b-table
                fixed
                striped
                hover
                :fields="fields.channel"
                :items="channel.items"
                :busy="channel.isBusy"
                show-empty
                empty-text="No matching records found"
              >
                <template v-slot:cell(total_member)="data">
                  <span>
                    {{ data.item.total_member | numeral("0,0") }}
                  </span>
                </template>
              </b-table>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
import ChartLoading from "@/components/loading/ChartLoading";
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
import BasketChart from "./BasketChart";
import Multiselect from "vue-multiselect";

function getRandomColor() {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return "rgb(" + r + "," + g + "," + b + ")";
}
export default {
  components: {
    Multiselect,
    InputDatePickerFilter,
    ChartLoading,
    BasketChart,
  },
  data() {
    return {
      isLoading: true,
      filter: {
        MemberTierFilter: [],
        ChannelFilter: [],
        DateFrom: "",
        DateTo: "",
        TypeDateFilter: 2,
        LastDays: 30,
      },
      filterFreeze: {
        MemberTierFilter: [],
        ChannelFilter: [],
        DateFrom: "",
        DateTo: "",
        TypeDateFilter: 2,
        LastDays: 30,
      },
      options: {
        memberTiers: [],
        channelTypes: [],
        dateType: [
          { text: "Last 7 Days", value: 7 },
          { text: "Last 30 Days", value: 30 },
          { text: "Last 365 Days", value: 365 },
          { text: "Custom", value: 0 },
        ],
      },
      chartList: [
        {
          name: "Connect Channel",
          filterValue: "Month",
          text: "Total Member",
          filter: ["Day", "Month", "Year", "Export"],
          label: [],
          data: [],
          export: "report/date",
          get: () => this.getConnectChannelChart(),
          type: "bar",
          isFetch: false,
          isChart: true,
        },
        {
          name: "Connect Channel",
          get: () => this.getConnectChannelList(),
          key: "channel",
          export: "report",
          filterValue: "",
          filter: ["Export"],
        },
        {
          name: "Total Connect Channel",
          get: () => this.getTotalCustomer(),
          key: "channel",
          export: "report",
          filterValue: "",
          filter: ["Export"],
        },
      ],
      fields: {
        channel: [
          {
            key: "channel_name",
            label: "Channel Name",
            class: "w-6",
            sortable: true,
            text: "Total",
          },
          {
            key: "total_member",
            label: "Total Member",
            thClass: "w-6",
            sortable: true,
          },
        ],
        totalCustomer: [
          {
            key: "channel_name",
            label: "Channel Name",
            class: "w-6",
            sortable: true,
            text: "Total",
          },
          {
            key: "total_member",
            label: "Total Member",
            thClass: "w-6",
            sortable: true,
          },
        ],
      },
      channel: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      totalCustomer: {
        items: [],
        isBusy: false,
        rows: 0,
        filter: {
          SortColumnId: 0,
          SortType: true,
          sortBy: "",
          sortDesc: "",
        },
      },
      stat: {},
      isFilterProduct: false,
      initFilterDate: {
        MinDate: "",
        MaxDate: "",
      },
    };
  },
  async mounted() {
    this.initDateFilter();
    await this.getFilter();
    await this.getData();
    this.isLoading = false;
  },
  methods: {
    async getData() {
      return await Promise.all([
        this.getStat(),
        // this.getTotalCustomer(),
        this.getConnectChannelChart(),
        this.getConnectChannelList(),
        this.getTotalCustomer(),
      ]);
    },
    handleDateFormat(type, date) {
      let value = "";
      if (type === 2)
        value = moment(date, "MM/YYYY").format("YYYY-MM-DDT00:00:00");
      else value = moment(date).format("YYYY-MM-DDT00:00:00");
      return value;
    },
    handleDateFormatLabel(type, date) {
      let value = "";
      if (type === 1) value = moment(date).format("YYYY");
      else if (type === 2) value = moment(date).format("MM/YYYY");
      else value = moment(date, "DD/MM/YYYY").format("DD/MM/YYYY");
      return value;
    },
    initDateFilter() {
      let toDay = moment().format("YYYY-MM-DDT00:00:00");
      // this.initFilterDate.MinDate = moment(toDay).day(-365).format();
      this.initFilterDate.MaxDate = toDay;
    },
    async getConnectChannelChart() {
      this.chartList[0].isFetch = true;
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/dashboard/customer_connect/date`,
        this.filterFreeze
      );

      let label = [];
      var dataSet = [];
      let dataList = [];

      label = (await res.data.detail.map((el) => el.group_date)) || [];

      console.log(label);
      if (res.data.detail.length > 0) {
        await res.data.detail[0].data.forEach((element, index) => {
          let color = getRandomColor();
          dataSet.push({
            order: index,
            label: element.channel_name,
            data: [],
            borderColor: color,
            backgroundColor: color,
            fill: false,
          });
        });

        if (this.filterFreeze.TypeDateFilter == 1) {
          await dataList.map(
            (el) =>
              (el.group_date = this.handleDateFormat(
                this.filterFreeze.TypeDateFilter,
                el.group_date
              ))
          );
        }

        for (const val of dataSet) {
          let tempObj = [];

          for (const list of res.data.detail) {
            let data = list.data.find((el) => el.channel_name == val.label);
            tempObj.push(data.total_member);
          }
          val.data = tempObj;
        }
      }

      // let labelChart = label.map((el) =>
      //   this.handleDateFormatLabel(this.filter.TypeDateFilter, el)
      // );
      this.chartList[0].label = label;
      this.chartList[0].data = dataSet;
      this.chartList[0].isFetch = false;
    },
    async getStat() {
      let date =
        this.filter.LastDays == 0
          ? `${this.$moment(this.filter.DateFrom).format(
              "DD MMM YYYY"
            )} - ${this.$moment(this.filter.DateTo).format("DD MMM YYYY")}`
          : `Last ${this.filter.LastDays} Days`;
      this.$emit("setStat", null, date);
    },
    async getConnectChannelList() {
      this.channel.isBusy = true;
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/dashboard/customer_connect`,
        {
          ...this.filterFreeze,
        }
      );

      this.channel.items = res.data.detail;
      this.channel.rows = this.channel.items.length;
      this.channel.isBusy = false;
    },
    async getTotalCustomer() {
      this.totalCustomer.isBusy = true;
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/dashboard/customer_connect/analysis`,
        {
          ...this.filterFreeze,
        }
      );
      let stat = {
        totalCustomer: res.data.detail.total_customer,
        percentCustomer: res.data.detail.percent_customer,
      };
      let date =
        this.filter.LastDays == 0
          ? `${this.$moment(this.filter.DateFrom).format(
              "DD MMM YYYY"
            )} - ${this.$moment(this.filter.DateTo).format("DD MMM YYYY")}`
          : `Last ${this.filter.LastDays} Days`;
      this.$emit("setStat", stat, date);
      // this.totalCustomer.items = res.data.detail;
      // this.totalCustomer.rows = this.totalCustomer.items.length;
      // this.totalCustomer.isBusy = false;
    },
    async getFilter() {
      const res = await this.$Axios(
        `${process.env.VUE_APP_REPORT_API}/dashboard/Filter/CustomerConnect`
      );

      this.options = {
        channelTypes: res.data.detail.channel,
        memberTiers: res.data.detail.member_tier,
        dateType: [
          { text: "Last 7 Days", value: 7 },
          { text: "Last 30 Days", value: 30 },
          { text: "Last 365 Days", value: 365 },
          { text: "Custom", value: 0 },
        ],
      };
    },
    async export(path) {
      this.$EventBus.$emit("showLoading");
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/dashboard/customer_connect/${path}`,
        this.filterFreeze,
        {
          responseType: "blob",
        }
      );

      let data = res;

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", path + `.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$EventBus.$emit("hideLoading");
    },
    async handleChartFilter(filter, chart) {
      let value = 0;
      if (filter == "Day") value = 3;
      if (filter == "Month") value = 2;
      if (filter == "Year") value = 1;
      if (filter == "Export") return this.export(chart.export);
      if (filter != "Export") chart.filterValue = filter;
      this.filter.TypeDateFilter = value;
      this.filterFreeze.TypeDateFilter = value;
      await chart.get();
    },
    async clearFilter() {
      this.filter = {
        MemberTierFilter: [],
        ChannelFilter: [],
        DateFrom: "",
        DateTo: "",
        TypeDateFilter: 2,
        LastDays: 30,
      };
      this.filterFreeze = {
        MemberTierFilter: [],
        ChannelFilter: [],
        DateFrom: "",
        DateTo: "",
        TypeDateFilter: 2,
        LastDays: 30,
      };
      await this.chartList.forEach((chart) => {
        if (chart.isChart) chart.filterValue = "Month";
      });

      this.$refs.filter.hide();
      this.filterActions();
    },
    filterActions() {
      if (this.filter.DateFrom)
        this.filter.DateFrom = this.$moment(this.filter.DateFrom).format(
          "YYYY-MM-DD"
        );
      if (this.filter.DateTo)
        this.filter.DateTo = this.$moment(this.filter.DateTo).format(
          "YYYY-MM-DD"
        );

      this.filterFreeze = { ...this.filter };
      for (const [keys, value] of Object.entries(this.filter)) {
        if (typeof value == "object") {
          this.filterFreeze[keys] = value.map((el) => el.id);
        }
      }

      this.getData();
    },
  },
};
</script>

<style>
.border-none {
  border: unset;
}
.underline {
  text-decoration: underline;
}
.text-highlight {
  color: var(--primary-color);
  font-weight: bold;
}
.text-gray {
  color: whitesmoke;
}
.chart-title-name {
  font-size: 18px;
  font-weight: bold;
  color: black;
  /* margin-bottom: 0.75rem; */
}

.input-select .select-custom label {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
</style>
